<template lang="html">
  <div class="banner">
    <img class="banner__image" src="@/assets/images/banner.svg" alt="Banner" />
    <div class="banner__text">
      <div class="banner__subtitle" v-if="initialData.subtitle">
        {{ initialData.subtitle }}
      </div>
      <div class="banner__title" v-if="initialData.title">
        {{ initialData.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.banner {
  position: relative;
  margin-left: -#{$containerPadding};

  &__image {
    height: 147px;
  }

  &__text {
    padding-left: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__title {
    font-weight: 600;
    font-size: 60px;
    line-height: 1;
  }

  &__subtitle {
    @include tcl-big-text;
    font-weight: 600;
  }
}
</style>
